import { OAuthCallbackKey, OAuthSamlCallbackKey } from './constants';

export const openOauthWindow = (url: string, callback: Function) => {
  let oAuthCallback: ReturnType<typeof window.open> = null;
  const callbackFunc = (event: MessageEvent) => {
    if (event.origin !== window.location.origin) {
      return;
    }

    if (event.data.type === OAuthCallbackKey || event.data.type === OAuthSamlCallbackKey) {
      window.removeEventListener('message', callbackFunc);
      oAuthCallback?.close();
      callback(event.data.payload);
    }
  };

  window.addEventListener('message', callbackFunc, false);
  oAuthCallback = window.open(url, '_blank');
};

import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { useLatestRef } from '@prophecy/utils/react/hooks';
import { useEffect } from 'react';
import { Controller as ReactController } from 'react-hook-form';
import { WithHelpText } from './utils';
export const Controller = (_a) => {
    var { render, tooltip, helpText, disabled, shouldUnregister, unregisterState = {} } = _a, props = __rest(_a, ["render", "tooltip", "helpText", "disabled", "shouldUnregister", "unregisterState"]);
    let getContent = (fieldProps) => {
        var _a, _b;
        const { field, fieldState } = fieldProps;
        const updatedField = Object.assign(Object.assign({}, field), { variant: fieldState.error ? 'error' : 'primary', helpText: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, required: Boolean((_b = props.rules) === null || _b === void 0 ? void 0 : _b.required), onChange: (value) => {
                return field.onChange(value === undefined ? null : value);
            }, tooltip });
        if (disabled)
            updatedField.disabled = disabled;
        let content = render(Object.assign(Object.assign({}, fieldProps), { field: updatedField }));
        if (helpText) {
            content = (_jsx(WithHelpText, { helpText: helpText, variant: 'primary', children: content }));
        }
        return content;
    };
    // TODO: remove shouldUnregister for next release
    const unregisterStateRef = useLatestRef(unregisterState);
    useEffect(() => () => {
        var _a;
        if (shouldUnregister) {
            (_a = props.control) === null || _a === void 0 ? void 0 : _a.unregister(props.name, Object.assign({ keepValue: true, keepDefaultValue: true, keepDirty: true, keepTouched: true }, unregisterStateRef.current));
        }
    }, [props.control, props.name, shouldUnregister, unregisterStateRef]);
    return _jsx(ReactController, Object.assign({ render: getContent }, props));
};

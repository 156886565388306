import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useControlledState } from '@prophecy/utils/react/hooks';
import RCTree from 'rc-tree';
import { useEffect, useRef, useState } from 'react';
import { Button } from '../Button';
import { Checkbox } from '../Checkbox';
import { Ellipsis } from '../Ellipsis';
import { CaretRightIcon } from '../Icons';
import { useBoxDimension } from '../ViewBox';
import { StyledTree, StyledTitleWrap, StyledTitle, StyledTitleInfo } from './styled';
import { TREE_CHECKBOX_CHECKED_CLASS, TREE_CHECKBOX_CLASS, TREE_CHECKBOX_DISABLED_CLASS, TREE_CHECKBOX_INDETERMINATE_CLASS, TREE_NODE_CLASS, TREE_SWITCHER_CLASS, tokens } from './tokens';
function TreeCheckbox({ variant }) {
    const ref = useRef(null);
    const [{ checked, indeterminate, disabled }, setSelectCheckboxState] = useState({
        checked: false,
        indeterminate: false,
        disabled: false
    });
    /**
     * Note: RC tree doesn't allow changing checkbox element, instead it renders
     * an element with classnames, to control the style using classnames.
     * This is a hack to get the checked state from its parent element.
     *
     * We want to call this effect every time it render's so we can capture the side-effect of className
     * The infinite setState check is done manually. That's whys eslint is disabled.
     */
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    useEffect(() => {
        var _a;
        const rcTreeCheckbox = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.closest(`.${TREE_CHECKBOX_CLASS}`);
        const _checked = Boolean(rcTreeCheckbox === null || rcTreeCheckbox === void 0 ? void 0 : rcTreeCheckbox.classList.contains(TREE_CHECKBOX_CHECKED_CLASS));
        const _indeterminate = Boolean(rcTreeCheckbox === null || rcTreeCheckbox === void 0 ? void 0 : rcTreeCheckbox.classList.contains(TREE_CHECKBOX_INDETERMINATE_CLASS));
        const _disabled = Boolean(rcTreeCheckbox === null || rcTreeCheckbox === void 0 ? void 0 : rcTreeCheckbox.classList.contains(TREE_CHECKBOX_DISABLED_CLASS));
        if (_checked !== checked || _indeterminate !== indeterminate || _disabled !== disabled) {
            setSelectCheckboxState({ checked: _checked, indeterminate: _indeterminate, disabled: _disabled });
        }
    });
    return (_jsx("div", { ref: ref, children: _jsx(Checkbox, { checked: checked, indeterminate: indeterminate, disabled: disabled }) }));
}
const useActiveKey = (key) => {
    const [activeKey, setActiveKey] = useState();
    useEffect(() => {
        setActiveKey(key);
        // set activeKey on initial load only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (activeKey)
            setActiveKey(undefined);
    }, [activeKey]);
    return activeKey;
};
export function Tree(_a) {
    var { checkable = false, selectable = false, virtual, checkedKeys, defaultCheckedKeys, onCheck, className, style, variant, treeData, initialActiveKey } = _a, restProps = __rest(_a, ["checkable", "selectable", "virtual", "checkedKeys", "defaultCheckedKeys", "onCheck", "className", "style", "variant", "treeData", "initialActiveKey"]);
    const activeKey = useActiveKey(virtual ? initialActiveKey : undefined);
    const [_checkedKeys, updatedCheckedKeys] = useControlledState({
        value: checkedKeys,
        defaultValue: defaultCheckedKeys,
        onChange: (checkedKeys, checkedInfo) => {
            onCheck === null || onCheck === void 0 ? void 0 : onCheck(checkedKeys, checkedInfo);
        }
    });
    const rootRef = useRef(null);
    const containerDim = useBoxDimension(() => rootRef.current);
    const handleTreeLabelClick = (e) => {
        var _a, _b, _c;
        (_c = (_b = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.closest(`.${TREE_NODE_CLASS}`)) === null || _b === void 0 ? void 0 : _b.querySelector(`.${TREE_SWITCHER_CLASS}`)) === null || _c === void 0 ? void 0 : _c.click();
    };
    return (_jsx(StyledTree, { ref: rootRef, onClick: handleTreeLabelClick, checkable: checkable, selectable: selectable, className: className, style: style, children: virtual && (containerDim === null || containerDim === void 0 ? void 0 : containerDim.height) === undefined ? null : (_jsx(RCTree, Object.assign({ height: virtual ? containerDim === null || containerDim === void 0 ? void 0 : containerDim.height : undefined, virtual: virtual, activeKey: activeKey, itemHeight: virtual ? parseInt(tokens.Node.height) : undefined, checkable: checkable ? _jsx(TreeCheckbox, { variant: variant }) : undefined, prefixCls: 'ui-tree', checkedKeys: _checkedKeys, onCheck: updatedCheckedKeys, selectable: selectable, switcherIcon: ({ isLeaf, expanded, className }) => {
                if (isLeaf)
                    return null;
                return (_jsx(CaretRightIcon, { type: 'extended', size: 'xs', color: tokens.ExpandIcon.color, className: className, rotate: expanded ? 90 : 0 }));
            }, titleRender: ({ title, info, disabled }) => {
                return (_jsx(Button, { variant: 'plain', disabled: disabled, children: _jsxs(StyledTitleWrap, { direction: 'horizontal', align: 'space-between', alignY: 'center', children: [_jsx(StyledTitle, { grow: '1', children: _jsx(Ellipsis, { tooltip: true, children: title }) }), info && _jsx(StyledTitleInfo, { children: info })] }) }));
            }, treeData: treeData }, restProps))) }));
}

import cron from 'cron-validate';
import { dump, load } from 'js-yaml';
import { castArray, isNil } from 'lodash-es';
import isEmail from 'validator/lib/isEmail';
import { getDateRegex } from './date';
export const EntityNameValidationMessage = `Only alphanumeric[A-Z, a-z, 0-9], underscore (_) and hyphen (-) are allowed`;
// eslint-disable-next-line no-useless-escape
export const EntityNameRegex = /^[\w\-]+$/;
export const FolderWithFileNameRegext = /^[\w\-./]+$/;
export const NodeNameRegex = /^[\w\s\-.]+$/;
export const AlphaNumericRegex = '^[a-zA-Z0-9]*$';
const Length8to64Regex = new RegExp('^(?=.{8,64}$)');
const AtLeastOneDigitRegex = new RegExp('^(?=.*[0-9])');
const LowerCaseRegex = new RegExp('^(?=.*[a-z])');
const UpperCaseRegex = new RegExp('^(?=.*[A-Z])');
const AtLeastOneSpecialCharRegex = new RegExp('^(?=.*[-!@#$+%()=.^&?*])');
const NoWhiteSpaceRegex = new RegExp('^(?=.*[^\\s])');
export const validatePassword = (password) => {
    const length8to64 = Length8to64Regex.test(password);
    const oneDigit = AtLeastOneDigitRegex.test(password);
    const upperCase = UpperCaseRegex.test(password);
    const lowerCase = LowerCaseRegex.test(password);
    const specialChar = AtLeastOneSpecialCharRegex.test(password);
    const noWhiteSpace = NoWhiteSpaceRegex.test(password);
    return {
        length8to64,
        oneDigit,
        upperCase,
        lowerCase,
        specialChar,
        noWhiteSpace
    };
};
export const isAlphabet = (str) => {
    return str.match(/^[a-z]+$/i) !== null;
};
export const isAlphaNumeric = (str) => {
    return str.match(/^[a-z0-9]+$/i) !== null;
};
export function validateFileName(str) {
    return /^[a-zA-Z0-9-_]+(\.[a-zA-Z0-9]+)$/.test(str);
}
export function validateFolderName(str) {
    // eslint-disable-next-line no-useless-escape
    return /^[a-zA-Z0-9-_\/]+$/.test(str);
}
export const validateFilePath = (name) => {
    return !name || /^[\w\-/]*?[\w-]+\.[a-zA-Z0-9]+$/.test(name) ? undefined : 'Please enter valid path';
};
export function validateAlphanumericUnderScoreDotHyphen(str) {
    // eslint-disable-next-line no-useless-escape
    return /^[\w\-./]+$/.test(str)
        ? undefined
        : `Only alphanumeric[A-Z, a-z, 0-9], underscore (_), dot (.) and hyphen (-) are allowed`;
}
export const validateAlphaNumericAndUnderscore = (str) => {
    if (!str)
        return;
    const valid = str.match(/^[a-z0-9_]+$/i) !== null;
    return valid ? undefined : 'Only alphanumeric[A-Z, a-z, 0-9] and underscore (_) are allowed.';
};
export function validateGemName(name) {
    return name.match(/^[a-zA-Z][A-Za-z0-9]*$/) ? undefined : `Only alphanumeric[A-Z, a-z, 0-9] is allowed.`;
}
export function validateFirstAlphabetChar(name) {
    const firstStr = name.charAt(0);
    if (!isAlphabet(firstStr)) {
        return 'First character should be an alphabet.';
    }
}
export function validateFirstAlphaNumericChar(name) {
    const firstStr = name.charAt(0);
    if (!isAlphaNumeric(firstStr)) {
        return 'First character should be alphanumeric.';
    }
}
export function validatePipelineName(name) {
    return validateFirstAlphabetChar(name) || validateEntityName(name);
}
export function validateEntityName(name) {
    const firstCharError = validateFirstAlphaNumericChar(name);
    if (firstCharError) {
        return firstCharError;
    }
    return EntityNameRegex.test(name) ? undefined : EntityNameValidationMessage;
}
export function validateNodeName(name) {
    const firstCharError = validateFirstAlphaNumericChar(name);
    if (firstCharError) {
        return firstCharError;
    }
    return NodeNameRegex.test(name)
        ? undefined
        : 'Only alphanumeric[A-Z, a-z, 0-9], space, underscore (_), hyphen (-) and dot (.) are allowed.';
}
export function validateEmail(value) {
    return !value ? 'Email is required' : isEmail(value) ? undefined : 'Invalid Email';
}
export function validateJobEmail(onFailure, onStart, onSuccess, value) {
    if (onFailure || onStart || onSuccess) {
        return !value ? 'Email is required' : isEmail(value) ? undefined : 'Invalid Email';
    }
}
export function validateMutipleCommaSeparatedJobEmail(onFailure, onStart, onSuccess, value) {
    const emails = value === null || value === void 0 ? void 0 : value.split(',');
    const someEmailIsInvalid = emails === null || emails === void 0 ? void 0 : emails.some((email) => {
        return !isEmail(email.trim());
    });
    let required = undefined;
    if (onFailure || onStart || onSuccess) {
        required = 'Email is required';
    }
    return !value ? required : someEmailIsInvalid ? 'One or more emails are not valid' : undefined;
}
export function validateCron(value = '', { isQuartz } = {}) {
    if (!value)
        return;
    const valid = cron(value, isQuartz
        ? {
            override: {
                useSeconds: true,
                useYears: value.split(' ').length === 7,
                useAliases: true,
                useBlankDay: true,
                useLastDayOfMonth: true,
                useLastDayOfWeek: true,
                useNearestWeekday: true,
                useNthWeekdayOfMonth: true
            }
        }
        : {
            override: {
                useAliases: true
            }
        }).isValid();
    return valid ? undefined : `Invalid ${isQuartz ? 'quartz' : 'unix'} expression`;
}
export function validateNumInRange(value, min, max) {
    let errorMsg = '';
    errorMsg =
        typeof min === 'number' && typeof max !== 'number' && value < min
            ? `Number should be greater than ${min}`
            : errorMsg;
    errorMsg =
        typeof min !== 'number' && typeof max === 'number' && max < value ? `Number should be less than ${max}` : errorMsg;
    errorMsg =
        typeof min === 'number' && typeof max === 'number' && value < min && max < value
            ? `Number should be in the range ${min} to ${max}`
            : errorMsg;
    if (value % 1 !== 0) {
        errorMsg = 'Floating values are not allowed';
    }
    return errorMsg ? errorMsg : undefined;
}
export function validatePackageName(value = '') {
    if (!value)
        return;
    else if (!value.match(/^[a-zA-Z]/)) {
        return 'Package name should start with a letter';
        // eslint-disable-next-line no-useless-escape
    }
    else if (!value.match(/^[\w\.]+$/)) {
        return 'Package name should contain only alphanumeric, underscore and .';
    }
}
export function validatePhoneNumber(value = '') {
    if (!value)
        return;
    else if (!value.match(/^\d{10}$/)) {
        return 'Phone number should be 10 digits';
    }
}
export function validatePhoneNumberLen(value = '') {
    const stringLen = value.length;
    return stringLen <= 15 ? undefined : 'Phone number should be less than 15 digits';
}
const tryUrl = (url, onError) => {
    try {
        new URL(url);
    }
    catch (e) {
        return onError();
    }
};
const defaultProtocols = ['http', 'https'];
export const validateURL = ({ allowedProtocols, protocolRequired = Boolean(allowedProtocols) } = {}) => (value = '') => {
    if (!value)
        return;
    if (protocolRequired) {
        const protocols = allowedProtocols ? castArray(allowedProtocols) : defaultProtocols;
        const protocolRegex = new RegExp(`^(${protocols.join('|')})://`);
        if (!protocolRegex.test(value)) {
            return `URL should start with ${protocols.map((p) => `${p}://`).join(' or ')}`;
        }
    }
    // add dummy protocol, to allow urls without protocol
    return tryUrl(value, () => tryUrl(`http://${value}`, () => 'Invalid URL'));
};
export function validateSQLProjectName(value = '') {
    if (!value)
        return;
    else if (!value.match(/^[a-zA-Z]/)) {
        return 'SQL Project name should start with a letter';
    }
    else if (!value.match(/^[^\d\W]\w*$/)) {
        return 'SQL Project name should contain only alphanumeric and underscore';
    }
}
export function isValidYML(value) {
    let errorMessage = '';
    try {
        const parsedYaml = load(value);
        value = dump(parsedYaml);
    }
    catch (e) {
        const error = e;
        errorMessage = error.name + ': ' + error.reason + ': ' + error.message;
    }
    return errorMessage ? errorMessage : true;
}
export function validateWordWithHyphen(value = '') {
    if (!value)
        return;
    if (!/^[\w-]+$/.test(value)) {
        return 'Only alphanumeric[A-Z, a-z, 0-9], underscore (_) and hyphen (-) are allowed.';
    }
}
export function validateWordWithHyphenDotAndSlash(value = '') {
    if (!value)
        return;
    if (!/^[\w\-./]+$/.test(value)) {
        return 'Only alphanumeric[A-Z, a-z, 0-9], underscore (_), dot (.), hyphen (-) and slash (/) are allowed.';
    }
}
export const validateDBTVersion = (value) => {
    return (value === null || value === void 0 ? void 0 : value.split(/\s*,\s*/).every((val) => {
        return /^(>|>=|<=|<)?(\d+\.\d+\.\d+)$/.test(val);
    }))
        ? undefined
        : 'Version is invalid';
};
export const validateNumber = ({ min = Number.MIN_SAFE_INTEGER, max = Number.MAX_SAFE_INTEGER, allowDecimals = true } = {}) => (value) => {
    if (value === undefined)
        return;
    if (Number(value) < min)
        return 'Minimum value is ' + min;
    if (Number(value) > max)
        return 'Maximum value is ' + max;
    if (!allowDecimals && parseInt(`${value}`) < Number(value))
        return 'Decimals are not allowed';
};
function toYYYYMMDD(date) {
    return date.toISOString().split('T')[0];
}
function getUTCDateTime(inputDate) {
    var date = inputDate ? new Date(inputDate) : new Date();
    return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0);
}
// This is only for temporary purposes until we get a calendar date picker
export const validateDate = ({ min, max } = {}) => (value) => {
    if (!value)
        return;
    const isoRegex = getDateRegex('yyyy-mm-dd');
    if (!isoRegex.test(value))
        return 'Please enter a valid date in yyyy-mm-dd format';
    const selectedDate = getUTCDateTime(value);
    const minDate = min === null || min === void 0 ? void 0 : min();
    if (minDate) {
        const minDateTime = getUTCDateTime(minDate);
        if (selectedDate < minDateTime) {
            return `Please select a date on or after ${toYYYYMMDD(new Date(minDateTime))}`;
        }
    }
    const maxDate = max === null || max === void 0 ? void 0 : max();
    if (maxDate) {
        const maxDateTime = getUTCDateTime(maxDate);
        if (selectedDate > maxDateTime) {
            return `Please select a date on or before ${toYYYYMMDD(new Date(maxDateTime))}`;
        }
    }
};
export const validateReleaseTag = (value) => {
    if (!value)
        return;
    if (/^[-.]/.test(value)) {
        return 'Release version cannot start with - or .';
    }
    return !/^[A-Za-z0-9/_][A-Za-z0-9/._-]*$/.test(value)
        ? 'Valid release version should contain only alphanumeric characters and the symbols /, ., -, and _'
        : undefined;
};
export const validateJSON = (value) => {
    if (!value)
        return;
    try {
        JSON.parse(value);
    }
    catch (e) {
        return 'Please enter valid JSON';
    }
};
export const validateMaxLength = (length) => (value) => {
    if (value.length > length) {
        return `Should not exceed ${length} characters`;
    }
};
export const chainValidations = (...argMethods) => (value) => {
    var _a;
    if (isNil(value) || value === '')
        return;
    let errorMessage;
    const methods = [...argMethods];
    while (!errorMessage && methods.length) {
        errorMessage = (_a = methods.shift()) === null || _a === void 0 ? void 0 : _a(value);
    }
    return errorMessage;
};
export const validateJobName = chainValidations(validateMaxLength(100), validateEntityName);
export function validateConnectionName(name) {
    return /^[\w\-.]+$/.test(name)
        ? undefined
        : `Only alphanumeric[A-Z, a-z, 0-9], dot, hyphen and underscore are allowed.`;
}

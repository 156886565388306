import { BaseColor, getColorCode, Stack, theme, Tooltip } from '@prophecy/ui';
import {
  IconSizeType,
  NewIconVariant,
  ClockIcon,
  GlobeAIcon,
  HexagonBIcon,
  ColumnsCIcon,
  LongHexIcon,
  PyramidIcon,
  WingIcon,
  DiamondCIcon,
  FxIcon,
  PipelineIcon,
  DatabaseAIcon,
  BuildingBIcon,
  HexagonAIcon,
  DownloadDIcon,
  EntityLineageIcon,
  ContainerIcon,
  PentagonIcon,
  TableIcon,
  UserAIcon,
  UsersAIcon,
  TrillionIcon,
  SettingsBIcon,
  FileXmlIcon,
  MiniPipelineIcon,
  MiniHexagonBIcon,
  MiniPentagonIcon,
  MiniClockIcon,
  MiniHexagonAIcon,
  MiniContainerIcon,
  MiniDiamondCIcon,
  MiniFxIcon,
  MiniColumnsCIcon,
  MiniDatabaseAIcon,
  MiniPyramidIcon,
  SystemIcon,
  MiniLongHexIcon,
  MiniWingIcon,
  MiniTrillionIcon,
  UserIcon,
  SettingsDIcon,
  ActivityIcon,
  MiniRadiantIcon,
  MiniDodecaIcon
} from '@prophecy/ui/Icons';
import styled from 'styled-components';

import { UISpecCategory } from '../../Parser/types';
import { getUnknownGemName } from '../categoryMap';
import { Entity } from '../types/Entity';
const UNKNOWN_GEM = getUnknownGemName();
const MiniEntityIconMap = {
  [Entity.Pipeline]: { icon: MiniPipelineIcon, type: NewIconVariant.mini },
  [Entity.Configuration]: { icon: MiniPipelineIcon, type: NewIconVariant.mini },
  [Entity.Dataset]: { icon: MiniHexagonBIcon, type: NewIconVariant.mini },
  [Entity.Subgraph]: { icon: MiniPentagonIcon, type: NewIconVariant.mini },
  [Entity.Job]: { icon: MiniClockIcon, type: NewIconVariant.mini },
  [Entity.Model]: { icon: MiniHexagonAIcon, type: NewIconVariant.mini },
  [Entity.Snapshot]: { icon: MiniHexagonAIcon, type: NewIconVariant.mini },
  [Entity.Team]: { icon: UsersAIcon, type: NewIconVariant.default },
  [Entity.User]: { icon: UserIcon, type: NewIconVariant.mini },
  [Entity.Project]: { icon: MiniContainerIcon, type: NewIconVariant.mini },
  [Entity.Gem]: { icon: MiniDiamondCIcon, type: NewIconVariant.mini },
  [Entity.Package]: { icon: BuildingBIcon, type: NewIconVariant.default },

  [Entity.Fabric]: { icon: GlobeAIcon, type: NewIconVariant.default },
  [Entity.Lineage]: { icon: EntityLineageIcon, type: NewIconVariant.extended },
  [Entity.UDF]: { icon: MiniFxIcon, type: NewIconVariant.mini },
  [Entity.Source]: { icon: MiniHexagonBIcon, type: NewIconVariant.mini },
  [Entity.UnreferencedSource]: { icon: MiniHexagonBIcon, type: NewIconVariant.mini },
  [Entity.Seed]: { icon: MiniHexagonBIcon, type: NewIconVariant.mini },
  [Entity.Macro]: { icon: MiniFxIcon, type: NewIconVariant.mini },
  PipelineConfiguration: { icon: MiniPipelineIcon, type: NewIconVariant.mini },
  PipelineConfigurations: { icon: MiniPipelineIcon, type: NewIconVariant.mini },
  Expression: { icon: MiniFxIcon, type: NewIconVariant.mini },
  Column: { icon: MiniColumnsCIcon, type: NewIconVariant.mini },
  [Entity.Function]: { icon: MiniFxIcon, type: NewIconVariant.mini },
  Environment: { icon: GlobeAIcon, type: NewIconVariant.default },
  Database: { icon: MiniDatabaseAIcon, type: NewIconVariant.mini },
  [Entity.Table]: { icon: TableIcon, type: NewIconVariant.default },
  Transform: { icon: MiniPyramidIcon, type: NewIconVariant.mini },
  Custom: { icon: MiniLongHexIcon, type: NewIconVariant.mini },
  JoinSplit: { icon: MiniWingIcon, type: NewIconVariant.mini },
  [UISpecCategory.JoinSplit]: { icon: MiniWingIcon, type: NewIconVariant.mini },
  Trillion: { icon: MiniTrillionIcon, type: NewIconVariant.mini },
  MachineLearning: { icon: MiniLongHexIcon, type: NewIconVariant.mini },
  System: { icon: SystemIcon, type: NewIconVariant.mini },
  [Entity.Template]: { icon: FileXmlIcon, type: NewIconVariant.extended },
  [Entity.Transpiler]: { icon: DownloadDIcon, type: NewIconVariant.default },
  TranspilerConfig: { icon: SettingsBIcon, type: NewIconVariant.default },
  Pset: { icon: SettingsDIcon, type: NewIconVariant.default },
  SingularDataTest: { icon: ActivityIcon, type: NewIconVariant.default },
  GenericDataTest: { icon: ActivityIcon, type: NewIconVariant.default },
  Test: { icon: ActivityIcon, type: NewIconVariant.default },
  Operator: { icon: MiniRadiantIcon, type: NewIconVariant.mini },
  Sensor: { icon: MiniDodecaIcon, type: NewIconVariant.mini },
  SparkSQL: { icon: MiniRadiantIcon, type: NewIconVariant.mini },
  TriggerNotify: { icon: MiniTrillionIcon, type: NewIconVariant.mini },
  DataTransfer: {
    icon: MiniHexagonAIcon,
    type: NewIconVariant.mini
  },
  [UNKNOWN_GEM]: { icon: MiniDiamondCIcon, type: NewIconVariant.mini }
} as const;
export const EntityIconMap = {
  [Entity.Pipeline]: { icon: PipelineIcon, type: NewIconVariant.extended },
  [Entity.Configuration]: { icon: PipelineIcon, type: NewIconVariant.extended },
  [Entity.Dataset]: { icon: HexagonBIcon, type: NewIconVariant.default },
  [Entity.Subgraph]: { icon: PentagonIcon, type: NewIconVariant.default },
  [Entity.Job]: { icon: ClockIcon, type: NewIconVariant.default },
  [Entity.Model]: { icon: HexagonAIcon, type: NewIconVariant.default },
  [Entity.Snapshot]: { icon: HexagonAIcon, type: NewIconVariant.default },
  [Entity.Team]: { icon: UsersAIcon, type: NewIconVariant.default },
  [Entity.User]: { icon: UserAIcon, type: NewIconVariant.default },
  [Entity.Project]: { icon: ContainerIcon, type: NewIconVariant.default },
  [Entity.Gem]: { icon: DiamondCIcon, type: NewIconVariant.extended },
  [Entity.Package]: { icon: BuildingBIcon, type: NewIconVariant.default },

  [Entity.Fabric]: { icon: GlobeAIcon, type: NewIconVariant.default },
  [Entity.Lineage]: { icon: EntityLineageIcon, type: NewIconVariant.extended },
  [Entity.UDF]: { icon: FxIcon, type: NewIconVariant.extended },
  [Entity.Source]: { icon: HexagonBIcon, type: NewIconVariant.default },
  [Entity.UnreferencedSource]: { icon: HexagonBIcon, type: NewIconVariant.default },
  [Entity.Seed]: { icon: HexagonBIcon, type: NewIconVariant.default },
  [Entity.Macro]: { icon: FxIcon, type: NewIconVariant.extended },
  PipelineConfiguration: { icon: PipelineIcon, type: NewIconVariant.extended },
  Pset: { icon: SettingsDIcon, type: NewIconVariant.default },
  PipelineConfigurations: { icon: PipelineIcon, type: NewIconVariant.extended },
  Expression: { icon: FxIcon, type: NewIconVariant.extended },
  Column: { icon: ColumnsCIcon, type: NewIconVariant.default },
  [Entity.Function]: { icon: FxIcon, type: NewIconVariant.extended },
  Environment: { icon: GlobeAIcon, type: NewIconVariant.default },
  Database: { icon: DatabaseAIcon, type: NewIconVariant.default },
  [Entity.Table]: { icon: TableIcon, type: NewIconVariant.default },
  Transform: { icon: PyramidIcon, type: NewIconVariant.extended },
  Custom: { icon: LongHexIcon, type: NewIconVariant.extended },
  JoinSplit: { icon: WingIcon, type: NewIconVariant.extended },
  [UISpecCategory.JoinSplit]: { icon: WingIcon, type: NewIconVariant.extended },
  Trillion: { icon: TrillionIcon, type: NewIconVariant.extended },
  MachineLearning: { icon: LongHexIcon, type: NewIconVariant.extended },
  System: { icon: SystemIcon, type: NewIconVariant.default },
  [Entity.Template]: { icon: FileXmlIcon, type: NewIconVariant.extended },
  [Entity.Transpiler]: { icon: DownloadDIcon, type: NewIconVariant.default },
  TranspilerConfig: { icon: SettingsBIcon, type: NewIconVariant.default },
  SingularDataTest: { icon: ActivityIcon, type: NewIconVariant.default },
  GenericDataTest: { icon: ActivityIcon, type: NewIconVariant.default },
  Test: { icon: ActivityIcon, type: NewIconVariant.default },
  [UNKNOWN_GEM]: { icon: DiamondCIcon, type: NewIconVariant.extended }
} as const;

export type EntityIconType = keyof typeof EntityIconMap;

type Tone = `${BaseColor}`;
export function getTokens(tone: Tone) {
  return {
    color: getColorCode(tone, 600),
    backgroundType: {
      default: {
        color: getColorCode(tone, 600),
        background: getColorCode(tone, 50)
      },
      solid: {
        color: getColorCode(tone, 100),
        background: getColorCode(tone, 500)
      }
    }
  };
}

export const EntityToneMap = {
  [Entity.Pipeline]: BaseColor.primary,
  [Entity.Transpiler]: BaseColor.blueish_grey,
  [Entity.Configuration]: BaseColor.primary,
  [Entity.Dataset]: BaseColor.secondary,
  [Entity.Subgraph]: BaseColor.blue,
  [Entity.Job]: BaseColor.fuchsia,
  [Entity.Model]: BaseColor.pink,
  [Entity.Snapshot]: BaseColor.pink,
  [Entity.Team]: BaseColor.blueish_grey,
  [Entity.Project]: BaseColor.blueish_grey,
  [Entity.Gem]: BaseColor.blue,
  [Entity.Package]: BaseColor.orange,

  [Entity.Fabric]: BaseColor.green_light,
  [Entity.Lineage]: BaseColor.orange,
  [Entity.Source]: BaseColor.secondary,
  [Entity.UnreferencedSource]: BaseColor.secondary,
  [Entity.Seed]: BaseColor.secondary,
  [Entity.Macro]: BaseColor.purple,
  PipelineConfiguration: BaseColor.primary,
  PipelineConfigurations: BaseColor.primary,
  Expression: BaseColor.purple,
  Column: BaseColor.blue_light,
  [Entity.Function]: BaseColor.purple,
  [Entity.UDF]: BaseColor.teal,
  Environment: BaseColor.green_light,
  Database: BaseColor.yellow,
  [Entity.Table]: BaseColor.purple,
  Transform: BaseColor.fuchsia,
  Custom: BaseColor.fuchsia,
  JoinSplit: BaseColor.purple,
  [UISpecCategory.JoinSplit]: BaseColor.purple,
  Trillion: BaseColor.orange,
  MachineLearning: BaseColor.yellow,
  TranspilerConfig: BaseColor.green_light,
  [Entity.Template]: BaseColor.blueish_grey,
  [Entity.User]: BaseColor.blueish_grey,
  System: BaseColor.blueish_grey,
  Pset: BaseColor.green_light,
  SingularDataTest: BaseColor.orange,
  GenericDataTest: BaseColor.orange,
  Test: BaseColor.orange,
  Operator: BaseColor.secondary,
  Sensor: BaseColor.pink,
  SparkSQL: BaseColor.secondary,
  TriggerNotify: BaseColor.orange,
  DataTransfer: BaseColor.orange,
  [UNKNOWN_GEM]: BaseColor.blue
};
const MINI_ICON_SIZES: IconSizeType[] = ['xxxs', 'xxs', 'xs'];
export const IconWrapper = styled(Stack)<{ radius: string; background: string }>`
  background: ${(props) => props.background};
  border-radius: ${(props) => props.radius};
  flex-shrink: 0;
`;
type EntityIconKey = keyof typeof EntityIconMap & keyof typeof EntityToneMap;

interface EntityIconProps {
  entity: EntityIconKey;
  tooltip?: React.ReactNode;
  backgroundType?: 'default' | 'solid';
  size?: IconSizeType;
  color?: string;
  className?: string;
}
export function EntityIcon({ entity, tooltip, backgroundType, size = 'm', color, className }: EntityIconProps) {
  const iconMap = MINI_ICON_SIZES.includes(size) ? MiniEntityIconMap : EntityIconMap;
  const iconObj = iconMap[entity as EntityIconKey];

  if (!iconObj) {
    console.error(`No icon found for entity: ${entity}`);
    return null;
  }

  const { icon: Icon, type: iconType } = iconObj;
  const tokens = getTokens(EntityToneMap[entity as EntityIconKey]);
  const title = tooltip ?? entity;

  if (backgroundType) {
    const { background, color: bgColor } = tokens.backgroundType[backgroundType];
    return (
      <Tooltip title={title}>
        <IconWrapper
          radius={theme.radius.xs}
          align='center'
          padding={theme.spaces.x6}
          alignY='center'
          background={background}
          className={className}>
          {/* @ts-ignore */}
          <Icon type={iconType} size={size} color={bgColor} />
        </IconWrapper>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={title}>
      <span>
        {/* @ts-ignore */}
        <Icon type={iconType} color={color || tokens.color} size={size} className={className} />
      </span>
    </Tooltip>
  );
}

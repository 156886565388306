import { event as fullStoryEvent, getCurrentSessionURL as fullStoryGetCurrentSessionURL } from '@fullstory/browser';
import { ObjectLiteral } from '@prophecy/interfaces/generic';
import { isNodeEvnProduction } from '@prophecy/utils/env';
import { navigateAndRefresh } from '@prophecy/utils/history';
import SentryFullStory from '@sentry/fullstory';
import { init, reactRouterV6Instrumentation } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import { StyleSheetManager } from 'styled-components';

import { listenForNetwork } from './common/sentry';
import { isAuthRoute, isEmbeddedSingUpUrl, isPublicURL } from './common/url';
import { getAppMetadata, AppMetadataProvider } from './context/appMetadata';
import { MixPanelProvider } from './context/mixpanel/context';
import { AppMetadataProviderType } from './context/types';
import { fetchControlCentreAuth, fetchUsageMetrics, postUsageMetrics, updateTimeStamp } from './data/apis/athena';
import { ControlCenterResponse, UsgaeMetricsResponse } from './data/apis/types';
import { redirectToAuth } from './data/util';
import { MainApp } from './MainApp';
import { getGraphQlEndPoint } from './utils/getServerUrl';
import 'reactflow/dist/style.css';
import './common/appVersion';

const SentryAllowedDomains = ['app.prophecy.io'];

if (isNodeEvnProduction() && SentryAllowedDomains.includes(window.location.host)) {
  console.warn('Configuring Sentry');
  init({
    dsn: 'https://80b67469d06c4f53a053992a41b4bdf8@o449849.ingest.sentry.io/5433542',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      }),
      new SentryFullStory('prophecy', {
        client: { event: fullStoryEvent, getCurrentSessionURL: fullStoryGetCurrentSessionURL }
      })
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    beforeBreadcrumb(breadcrumb) {
      // filter out default graphql breadcrumb as we have custom breadcrumb for graphql
      return breadcrumb.category === 'fetch' && getGraphQlEndPoint() === breadcrumb.data?.url ? null : breadcrumb;
    }
  });
  listenForNetwork();
}

const returnMainApp = (data: AppMetadataProviderType, isLoggedIn: boolean) => {
  return (
    <StyleSheetManager>
      <AppMetadataProvider value={data}>
        <MixPanelProvider>
          <MainApp isLoggedIn={isLoggedIn} userId={data.user?.id as string} />
        </MixPanelProvider>
      </AppMetadataProvider>
    </StyleSheetManager>
  );
};

const updateUserMetrics = async () => {
  try {
    const metricsResponse = (await fetchUsageMetrics()) as UsgaeMetricsResponse;
    if (!metricsResponse?.data?.aggregateMetrics) {
      return;
    }
    const { data } = (await fetchControlCentreAuth()) as ControlCenterResponse;
    const { url, key } = data;
    if (!url || !key) {
      return;
    }

    const updateUsageMetricsResponse = (await postUsageMetrics(
      url,
      { aggregateMetrics: metricsResponse?.data?.aggregateMetrics },
      {
        Authorization: key
      }
    )) as ObjectLiteral;

    if (updateUsageMetricsResponse?.code === 200) {
      updateTimeStamp({ requestID: metricsResponse?.data?.requestID });
    }
  } catch (e) {
    console.log('error while updating usage metrics', e);
  }
};

const run = async () => {
  const data = await getAppMetadata();
  const pathname = window.location.pathname;
  // for embed url consider user as non logged in
  const isLoggedIn = Boolean(data?.user?.id) && !isEmbeddedSingUpUrl(pathname);

  if (isLoggedIn && isAuthRoute(pathname)) {
    navigateAndRefresh('/');
    return;
  } else if (!isLoggedIn && !isAuthRoute(pathname) && !isPublicURL(pathname)) {
    redirectToAuth();
    return;
  }

  if (isLoggedIn && data.globalConfig['prophecy.ui.usageMetrics.enabled']) {
    updateUserMetrics();
  }

  const rootElement = document.getElementById('root');
  if (rootElement) {
    ReactDOM.createRoot(rootElement).render(returnMainApp(data, isLoggedIn));
  } else {
    throw new Error('Could not mount react app as element #root was not found');
  }
};

run();

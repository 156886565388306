import { BASE_PATH } from '@prophecy/utils/history';
import { useGlobalContext } from '@prophecy/utils/react/utilify';
import { useMemo } from 'react';

import { OnboardingStatus } from '../../common/onboarding/types';
import { BackEndCodeLanguage } from '../../graphqlTypes/enums';
import {
  ALLOW_CONFIG_TAGS,
  ALLOW_PARAM_CONFIG,
  ALLOW_SECRET_TYPE_CONFIG,
  CARRY_FORWARD_SLUG_ENABLED,
  COPILOT_AUDIO_PROMPT_ENABLED,
  COPILOT_ENABLED_KEY,
  DBT_MACRO_ENABLED_KEY,
  DBT_PROJECT_DEPENDENCY_ENABLED_KEY,
  DBT_SHOW_ALL_PROVIDERS,
  EDIT_DEFAULT_TEMPLATE_ENABLED_KEY,
  ENABLE_SPARK_SORT_FILTER,
  isDebugMode,
  PROJECT_BUILD_ENABLED_KEY,
  SPARK_PROPHECY_MANAGED_TIMEOUT,
  UNDO_REDO_KEY
} from '../../utils/localstorage-keys';

export const useGlobalVariables = () => {
  const globalContext = useGlobalContext();
  const settings = useMemo(() => {
    try {
      // this is for dev purpose, ideally globalContext.globalConfig should be always available
      if (!globalContext.globalConfig) {
        throw new Error('Global config is not available');
      }
    } catch (error) {
      console.error(error);
    }
    return (globalContext.globalConfig || {}) as Record<string, unknown>;
  }, [globalContext.globalConfig]);

  return useMemo(
    () => ({
      getExternalGitPath: () => {
        const defaultPath = '/';
        const path = settings['prophecy.ui.externalgit.path'];
        if (!path) {
          return defaultPath;
        }
        return path;
      },
      get giteaUrl() {
        return settings['prophecy.ui.gitserver.url'] === '' ? undefined : settings['prophecy.ui.gitserver.url'];
      },
      get isAUTHBackendDatabase() {
        return settings['prophecy.ui.auth_backend'] === 'database';
      },
      get isCE() {
        return settings['prophecy.ui.IS_CE'] ?? true;
      },
      get isDarkCluster() {
        return settings['prophecy.ui.darkcluster.enabled'] ?? false;
      },
      get getOnboardingClusterId() {
        return settings['prophecy.ui.onboarding.db.clusterId'];
      },
      get isDeploymentEnabled() {
        return settings['prophecy.ui.cluster-screen.enabled'] ?? true;
      },
      get isExecutableCopyCodeEnabled() {
        return settings['prophecy.ui.workflow.code.copy.enabled'] ?? false;
      },
      get isOIDCEnabled() {
        return Boolean(settings['prophecy.ui.livy.oidc.enabled']);
      },
      get isFabricTrailEnabled() {
        return settings['prophecy.ui.fabric.trial.enabled'] ?? true;
      },
      get isCodeGenerationEnabled() {
        return settings['prophecy.ui.project.codeRegeneration.enabled'] ?? true;
      },
      get isPlibDependeciesEnabled() {
        return settings['prophecy.ui.editor.plibs-in-dependencies.enabled'] ?? false;
      },
      get isFabricCreationEnabled() {
        return settings['prophecy.ui.fabric.creation.enabled'] ?? true;
      },
      get isLineageEnabled() {
        return settings['prophecy.ui.lineage.enabled'] ?? true;
      },
      get isMeteringEnabled() {
        return settings['prophecy.ui.metering.enabled'] ?? false;
      },
      get isDatabricksConnectionsEnabled() {
        return Boolean(settings['prophecy.ui.databricks.connections.enabled']);
      },
      get isMigrationThingEnabled() {
        return settings['prophecy.ui.project.new-editor.enabled'] ?? false;
      },
      get isMixPanelEnabled() {
        return settings['prophecy.ui.mixpanel.enabled'] ?? false;
      },
      get isDependencyEnabled() {
        return settings['prophecy.ui.workflow.external.dependency'] ?? false;
      },
      get isDataQualityEnabled() {
        return settings['prophecy.ui.dataquality.expectation.enabled'] ?? true;
      },
      get isTranspilerDataAnalysisEnabled() {
        return settings['prophecy.ui.transpiler.analysis.enabled'] ?? false;
      },
      get hasTranspilerSqlLanguageEnabled() {
        return settings['prophecy.ui.transpiler.sql.enabled'] ?? false;
      },
      get isModeDropdownEnabledInWorkflowCreation() {
        return settings['prophecy.ui.workflow.mode.enabled'] ?? false;
      },
      get isPkgScreenEnabled() {
        return settings['prophecy.ui.package-screen.enabled'] ?? true;
      },
      get isPlaygroundEnabled() {
        return (settings['prophecy.ui.gembuilder.editor.enabled'] || isDebugMode()) ?? false;
      },
      get isPythonLanguageEnabled() {
        return settings['prophecy.ui.backend.python.enabled'] ?? false;
      },
      get getExecutionURL() {
        return settings['prophecy.ui.execution.url'] as string;
      },
      get isInterimDownloadAllowed() {
        return settings['prophecy.ui.execution.download.enabled'];
      },
      get isSQLProjectEnabled() {
        return Boolean(settings['prophecy.ui.backend.sql.enabled']);
      },
      get isBetaProvidersEnabled() {
        return (
          settings['prophecy.ui.backend.betaproviders.enabled'] ||
          localStorage.getItem(DBT_SHOW_ALL_PROVIDERS) === 'true'
        );
      },
      get isSearchEnabled() {
        return Boolean(settings['prophecy.ui.search.enabled']);
      },
      get isShowCodeInIframeEnabled() {
        return settings['prophecy.ui.gitserver_iframe_enabled'] === '1' ?? true;
      },
      get isGlobalAccessEnabled() {
        return settings['prophecy.ui.publish.global-access.enabled'] ?? false;
      },
      get isTeamCreationEnabled() {
        return settings['prophecy.ui.team.creation.enabled'] ?? true;
      },
      get isTourEnabled() {
        return settings['prophecy.ui.run.mode'] === 'prod' ?? false;
      },
      get getUnsetFabricStatusId() {
        return settings.unsetFabricStatusId as number;
      },
      setUnsetFabricStatusId: (fabricStatusId: number) => {
        settings.unsetFabricStatusId = fabricStatusId;
      },
      setFabricStatus: (isSet: boolean) => {
        settings.isFabricSet = isSet;
      },
      get getFabricStatus() {
        return settings.isFabricSet as boolean;
      },
      get getSparkOnboardingStatus() {
        return settings.onboardingStatus as OnboardingStatus;
      },
      setSparkOnboardingStatus: (value: OnboardingStatus) => {
        settings.onboardingStatus = value;
      },
      get getSqlOnboardingStatus() {
        return settings.sqlOnboardingStatus as OnboardingStatus;
      },
      setSqlOnboardingStatus: (value: OnboardingStatus) => {
        settings.sqlOnboardingStatus = value;
      },
      get isImportEnabled() {
        return settings['prophecy.ui.transpiler.import.enabled'] ?? false;
      },
      get isAlteryxDatasetEnabled() {
        return settings['prophecy.ui.transpiler.alteryx.datasets.enabled'] ?? false;
      },
      get isTranspilerJobEnabled() {
        return settings['prophecy.ui.transpiler.job.enabled'] ?? false;
      },
      get isAdvanceTranspilerEnabled() {
        return settings['prophecy.ui.transpiler.advanceOption.enabled'] ?? false;
      },
      get isWorkflowLanguageEnabled() {
        return settings['prophecy.ui.workflow.language.enabled'] ?? false;
      },
      get isAboutProphecyEnabled() {
        return settings['prophecy.ui.about.enabled'] ?? false;
      },
      get isProjectTemplateEnabled() {
        return settings['prophecy.ui.workflow.build.template.global.enabled'] ?? false;
      },
      get isSingleForkExtGitConfirmationEnabled() {
        return settings['prophecy.ui.singlefork.externalgit-confirmation.enabled'] ?? false;
      },
      get isCustomSchemaDi() {
        return settings['prophecy.ui.singlefork.externalgit-confirmation.enabled'] ?? false;
      },
      get shouldForceCustomSchemaForScript() {
        return settings['prophecy.ui.script.customSchema.enabled'] ?? false;
      },
      get unitTestEnabledForScript() {
        return settings['prophecy.ui.script.unittest.enabled'] ?? false;
      },
      get isProjectBuildEnable() {
        return localStorage.getItem(PROJECT_BUILD_ENABLED_KEY) === 'true';
      },
      get isDbtMacroEnable() {
        return localStorage.getItem(DBT_MACRO_ENABLED_KEY) === 'true';
      },
      get isDBTProjectDependencyEnabled() {
        return localStorage.getItem(DBT_PROJECT_DEPENDENCY_ENABLED_KEY) === 'true';
      },
      get isGithubEnabled() {
        return settings['prophecy.ui.git.github.enabled'] ?? false;
      },
      get getCloudProvider() {
        return settings['prophecy.ui.cloud.provider'] as string;
      },
      get isNewGembuilderEnabled() {
        return Boolean(settings['prophecy.ui.gembuilder.new.enabled']);
      },
      get isGemDeleteAllowed() {
        return Boolean(settings['prophecy.ui.gembuilder.delete.enabled']);
      },
      get isHistoryEnabled() {
        return settings['prophecy.ui.history.enabled'] || localStorage.getItem(UNDO_REDO_KEY) === 'true';
      },
      get isUploadUsageMetricsEnabled() {
        return settings['prophecy.ui.usageMetrics.enabled'] ?? false;
      },
      get isCopilotEnabled() {
        return (
          (settings['prophecy.ui.copilot.enabled'] || localStorage.getItem(COPILOT_ENABLED_KEY) === 'true') &&
          localStorage.getItem(COPILOT_ENABLED_KEY) !== 'false'
        );
      },
      get isReleaseV2Enabled() {
        return settings['prophecy.ui.releasev2.enabled'] as boolean | undefined;
      },
      get isCarryForwardOutputSlugEnabled() {
        return (
          (settings['prophecy.ui.carryForwardSlug.enabled'] as boolean | undefined) ||
          localStorage.getItem(CARRY_FORWARD_SLUG_ENABLED) === 'true'
        );
      },
      get isAirflowEnabled() {
        return Boolean(settings['prophecy.ui.airflow.enabled']);
      },
      get isAirflowTrialEnabled() {
        return Boolean(settings['prophecy.ui.airflow.trial.enabled']);
      },
      get isSynapseEnabled() {
        return Boolean(settings['prophecy.ui.spark.synapse.enabled']);
      },
      get defaultDatabricksAWSMachineType() {
        return (settings['prophecy.ui.databricks.job.size.default.aws.machine'] as string | undefined) || 'i3.xlarge';
      },
      get defaultDatabricksAWSRuntimeEngine() {
        return (
          (settings['prophecy.ui.databricks.job.size.default.aws.runtime_engine'] as string | undefined) || 'PHOTON'
        );
      },
      get isSparkProphecyManagedTimeoutEditable() {
        return localStorage.getItem(SPARK_PROPHECY_MANAGED_TIMEOUT) === 'true';
      },
      get isSparkSynapseExtraJarsEnabled() {
        return settings['prophecy.ui.spark.synapse.extra-jars.enabled'] as boolean | undefined;
      },
      get isSparkDataExplorerEnabled() {
        return (
          (settings['prophecy.ui.editor.workflow.spark.dataexplorer.enabled'] as boolean | undefined) ||
          localStorage.getItem(ENABLE_SPARK_SORT_FILTER) === 'true'
        );
      },
      get getDeleteAfterMergeDefault() {
        return (settings['prophecy.ui.deleteBranchAfterMerge.enabled'] as boolean) ?? false;
      },
      get isCustomDefaultTemplateEnabled() {
        return (
          settings['prophecy.ui.template.defaultTemplate.edit.enabled'] ||
          localStorage.getItem(EDIT_DEFAULT_TEMPLATE_ENABLED_KEY)
        );
      },
      get isSecretManagerEnabled() {
        return settings['prophecy.ui.secretManagement.enabled'] as boolean;
      },
      get isFabricEndpointEnabled() {
        return settings['prophecy.ui.execution.fabric.endpoint.enabled'] as boolean;
      },
      get getDefaultLanguageForProject() {
        return (settings['prophecy.ui.defaultProjectLanguage'] as BackEndCodeLanguage) || BackEndCodeLanguage.python;
      },
      get getIsFabricAccessibleToClusterAdmin() {
        return settings['prophecy.ui.allow.fabric.access.cluster.admins'] as boolean;
      },
      get showConfigTagsForTesting() {
        return (
          localStorage.getItem(ALLOW_CONFIG_TAGS) === 'true' &&
          window.location.pathname.startsWith(`${BASE_PATH}/ide/pipeline`)
        );
      },
      get isSecretTypeConfigEnabled() {
        return localStorage.getItem(ALLOW_SECRET_TYPE_CONFIG)
          ? localStorage.getItem(ALLOW_SECRET_TYPE_CONFIG) === 'true'
          : true;
      },

      get isParamConfigEnabled() {
        return localStorage.getItem(ALLOW_PARAM_CONFIG) === 'true';
      },
      get isOnDemandCompilationEnabled() {
        return settings['prophecy.ui.editor.workflow.spark.on_demand_compilation'] as boolean;
      },
      get isDatabricksOIDCEnabled() {
        return (settings['prophecy.ui.databricks.oidc.enabled'] as boolean) ?? false;
      },
      get isCopilotAudioPromptEnabled() {
        return (
          (settings['prophecy.ui.copilot.enable_audio'] as boolean) ||
          localStorage.getItem(COPILOT_AUDIO_PROMPT_ENABLED) === 'true'
        );
      }
    }),
    [settings]
  );
};

export type SecuredGlobalConfigType = ReturnType<typeof useGlobalVariables>;

/* eslint-disable*/
//@ts-nocheck
import { useEffect } from 'react';
import { useUser } from '../context/user';

const filteredEmails = [
  'tylerg@prophecy.io',
  'angela@prophecy.io',
  'sujay@prophecy.io'
  // 'vishal@simpledatalabs.com',
  // 'sudhanshu@prophecy.io'
];

/**
 * Aetion (aetion.com)
 * Bonafide Health (bonafidehealth.com)
 * Cobalt Health Solutions (cobalths.com)
 * Driftwood Hospitality Management (driftwoodcapital.com)
 * SAP SE (sap.com)
 * Texas Rangers (texasrangers.com)
 * Waterfall Asset Management (waterfallam.com)
 * Workday (workday.com)
 * Zions (zionsbancorp.com)
 */

const filteredCompanyDomains = [
  'aetion.com',
  'bonafidehealth.com',
  'cobalths.com',
  'driftwoodcapital.com',
  'sap.com',
  'texasrangers.com',
  'waterfallam.com',
  'workday.com',
  'zionsbancorp.com'
];

// this is marketing manage script so can be typescript and eslint ignored, instead of fixing them, so updating code becomes easier
export function useDelightSurvey() {
  const user = useUser();
  const { firstName, lastName, email, company } = user.user;

  useEffect(() => {
    const isProphecyTestUser = filteredEmails.includes(email);
    const isPaidCustomer = filteredCompanyDomains.some((domain) => email.match(new RegExp(`@(?:.*\\.)?${domain}$`)));

    if (!email || !(isProphecyTestUser || isPaidCustomer)) {
      return;
    }

    !(function (e, t, r, n) {
      if (!e[n]) {
        for (
          var a = (e[n] = []),
            i = [
              'survey',
              'reset',
              'config',
              'init',
              'set',
              'get',
              'event',
              'identify',
              'track',
              'page',
              'screen',
              'group',
              'alias'
            ],
            s = 0;
          s < i.length;
          s++
        ) {
          var c = i[s];
          a[c] =
            a[c] ||
            (function (e) {
              return function () {
                var t = Array.prototype.slice.call(arguments);
                a.push([e, t]);
              };
            })(c);
        }
        a.SNIPPET_VERSION = '1.0.1';
        var o = t.createElement('script');
        (o.type = 'text/javascript'),
          (o.async = !0),
          (o.src = 'https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/' + r + '/' + n + '.js');
        var p = t.getElementsByTagName('script')[0];
        p.parentNode.insertBefore(o, p);
      }
    })(window, document, 'oqe8NHnqsB4iekdZ', 'delighted');

    delighted.survey({
      email,
      name: `${firstName} ${lastName}`,
      properties: {
        company
      }
    });
  }, []);
}

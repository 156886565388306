import { jsx as _jsx } from "react/jsx-runtime";
import RcTable from 'rc-table';
import styled, { css } from 'styled-components';
import { Button } from '../Button';
import { PlusIcon, DragIcon } from '../Icons';
import { Stack } from '../Layout';
import { Spinner } from '../Spinner';
import { theme } from '../theme';
import { TABLE_BODY, TABLE_CELL_CLASS, TABLE_CELL_FIX_LEFT, TABLE_CELL_FIX_LEFT_LAST, TABLE_CELL_FIX_RIGHT, TABLE_CELL_FIX_RIGHT_LAST, TABLE_CELL_ROW_HOVER, TABLE_CLASS, TABLE_CONTAINER, TABLE_CONTENT, TABLE_FOOTER, TABLE_PLACEHOLDER, TABLE_ROW_CLASS, TABLE_TBODY, TABLE_THEAD_CLASS, tokens } from './tokens';
export const FULL_WIDTH_TR_CLS = 'tr_full_width';
export const CURRENT_ROW_HINT_WIDTH = '3px';
const TempTable = (props) => _jsx(RcTable, Object.assign({}, props));
export const VirtualizedTableWrapper = styled.div `
  min-height: var(--min-height);
`;
export const StyledTable = styled(RcTable) `
  font-family: ${tokens.Table.fontFamily};
  height: 100%;
  width: var(--width);
  max-width: 100%;
  table {
    table-layout: fixed;
    border-spacing: 0;
    font-size: ${tokens.Table.fontSize};
    font-weight: ${tokens.Table.fontWeight};
    width: 100%;
  }

  tr {
    height: ${tokens.Row.height};
  }

  .${TABLE_PLACEHOLDER} {
    height: 155px;
    td > div {
      position: sticky;
      left: 50%;
      width: 40%;
      transform: translateX(-50%);
    }
  }

  th,
  td {
    position: relative;
  }
  th,
  td {
    padding: 0 ${tokens.Cell.padding};
    background: ${theme.colors.white};
  }
  .${TABLE_CELL_FIX_LEFT},.${TABLE_CELL_FIX_RIGHT} {
    z-index: 2;
  }
  .${TABLE_CELL_FIX_LEFT_LAST} {
    border-right: 1px solid ${tokens.Table.borderColor};
  }
  .${TABLE_CELL_FIX_RIGHT_LAST} {
    border-left: 1px solid ${tokens.Table.borderColor};
  }
  .${TABLE_ROW_CLASS}.${FULL_WIDTH_TR_CLS} {
    .${TABLE_CELL_CLASS} {
      padding: 0px;
    }
  }
  .${TABLE_CELL_ROW_HOVER} {
    background-color: ${tokens.Row.hover.backgroundColor};
  }

  .reorder-cell {
    padding: 0;
  }

  .${TABLE_CLASS} {
    position: relative;
  }

  && .${TABLE_FOOTER} {
    ${({ data }) => ((data || []).length < 1 ? 'height: 0;' : '')}
  }

  .${TABLE_CONTAINER} {
    //To handle the empty table top border if header is not present
    border: 1px solid ${tokens.Table.borderColor};
    border-radius: ${tokens.Table.borderRadius};
    position: relative;
    background: white;
    overflow: hidden;
    .${TABLE_BODY}, &,
    table,
    .${TABLE_TBODY}, .${TABLE_BODY} ${VirtualizedTableWrapper}, .${TABLE_CONTENT} {
      ${({ data, title, footer }) => (data || []).length < 1
    ? `
     height: ${title || footer ? 'calc(100% - 85px)' : '100%'};
   
     [aria-hidden="true"]{
      height: 1px!important;
     }
     `
    : ''}
    }
  }
  .${TABLE_ROW_CLASS}[disabled] {
    background-color: ${tokens.Row.disabled.backgroundColor};
    .${TABLE_CELL_CLASS} {
      background-color: ${tokens.Row.disabled.backgroundColor};
    }
  }
  .${TABLE_ROW_CLASS}:not(:last-child) td,
  .${TABLE_THEAD_CLASS} th {
    border-bottom: 1px solid ${tokens.Table.borderColor};
    text-align: left;
  }

  .${TABLE_THEAD_CLASS} th {
    background-color: ${tokens.Head.backgroundColor};
    font-weight: ${tokens.Head.fontWeight};
  }

  .${TABLE_THEAD_CLASS} .${TABLE_CELL_CLASS} {
    &:hover {
      .column-sort-header-cell {
        button {
          display: flex !important; //keeping important as hidden property adds none with !important, only way to override is to use !important
        }
      }
    }

    & .column-sort-header-cell {
      cursor: pointer;
    }
  }

  && {
    .dummy-row {
      & td,
      &:hover > td {
        background-color: ${tokens.Row.DummyRow.backgroundColor};
      }
    }
  }

  .reorder-cell {
    border-left: ${tokens.Row.current.borderWith} solid transparent;
  }

  .drop-hint:not(.dummy-row) {
    position: relative;

    .reorder-cell:before {
      content: '';
      display: block;
      height: 4px;
      width: calc(100% + ${tokens.Row.current.borderWith});
      left: -2px;
      background: ${theme.colors.primary500};
      position: absolute;
      z-index: ${theme.zLayer.xxl};
    }

    .reorder-cell {
      border-left: ${tokens.Row.current.borderWith} solid ${tokens.Row.current.borderColor};
    }

    &.drop-before {
      .reorder-cell:before {
        top: 0;
      }
    }
    &.drop-after {
      .reorder-cell:before {
        bottom: 0;
      }
    }
  }
`;
export const StyledReorder = styled(Stack) `
  height: 100%;
  cursor: grab;
  user-select: none;
  margin-right: ${theme.spaces.x4};
  &:active {
    cursor: grabbing;
  }
`;
export const StyledMenuOutlined = styled(DragIcon) `
  font-size: ${tokens.Icon.fontSize};
  color: ${tokens.Icon.color};

  &:hover {
    color: ${tokens.Icon.hover.color};
  }
`;
export const StyledEmpty = styled.div `
  height: var(--height);
  display: flex;
  margin: 0;
  flex-direction: column;
  justify-content: center;
`;
export const EmptyMessage = styled(Stack) `
  height: var(--height);
`;
export const StyledSpinner = styled(Spinner) `
  position: relative;
`;
export const SelectionActionsContainer = styled(Stack) `
  position: absolute;
  top: 0;
  z-index: ${tokens.SelectionActions.zIndex};
  left: 70px;
  right: 0;
  height: ${tokens.Row.height};
  background-color: ${tokens.Head.backgroundColor};
  border: 1px solid ${tokens.Table.borderColor};
  border-bottom: 0;
  border-left: 0;
  border-top-right-radius: ${tokens.Table.borderRadius};
  padding: 0 ${tokens.SelectionActions.padding};
`;
export const RowActionButtonWrapper = styled.div `
  position: fixed;
  display: flex;
  z-index: ${tokens.SelectionActions.zIndex};
  transform: translateY(-50%);
`;
export const StyledDropdownOverlay = styled.div `
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: ${tokens.SelectionActions.zIndex};
`;
export const ghostDragElementStyles = `
  border-radius: ${tokens.Table.borderRadius};
  color: ${tokens.GhostDragElement.color};
  background-color: ${tokens.GhostDragElement.backgroundColor};
  padding: ${tokens.GhostDragElement.padding};
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;
export const TableAddonStyle = css `
  &,
  &:active,
  &[data-active='true'],
  &:focus {
    &,
    &:hover {
      border-left: 1px solid ${tokens.Table.borderColor};
      border-right: 1px solid ${tokens.Table.borderColor};
      border-top: 0;
      border-bottom: 0;
    }
  }
  background: ${tokens.Addon.background};
  color: ${tokens.Addon.color};
  height: ${tokens.Row.height};
  line-height: ${tokens.Row.height};
  flex-shrink: 0;
`;
export const TableAddButton = styled(Button).attrs((props) => {
    var _a;
    return ({
        variant: 'tertiaryGrey',
        block: true,
        icon: (_a = props.icon) !== null && _a !== void 0 ? _a : _jsx(PlusIcon, { type: 'default' })
    });
}) `
  ${TableAddonStyle}
  width: ${tokens.Row.height};
  min-width: ${tokens.Row.height};
  height: 100%;
`;
export const TableCellPrefix = styled(Stack) `
  ${TableAddonStyle}
  padding: 0 ${tokens.Cell.padding};
  margin-right: ${tokens.ExcelTable.Cell.padding};
  white-space: nowrap;
`;
export const TableActions = styled(Stack).attrs({ direction: 'horizontal' }) `
  visibility: hidden;

  ${StyledTable} tr:hover & {
    visibility: visible;
  }
`;
export const StyledCellRoot = styled(Stack) `
  min-height: ${tokens.Row.height};
`;
export const SortButton = styled(Button) `
  color: ${theme.colors.grey400};
  height: ${(props) => props.height}px;
  font-weight: ${theme.fontWeight.bold};
  line-height: ${theme.lineHeight.x16};
  ${(props) => props.height && `height: ${props.height}px;`}
  ${(props) => props.isActive && `color: ${theme.colors.primary600};`}
  &:hover {
    color: ${theme.colors.grey600};
  }
`;
export const ColumnResizeIcon = styled.div `
  position: absolute;
  cursor: col-resize;
  top: 50%;
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  transform: translateY(-50%);
  right: ${tokens.Table.columnResizer.right};
  z-index: ${tokens.Table.columnResizer.zIndex};
  color: ${tokens.Table.columnResizer.color};
  font-size: ${tokens.Table.columnResizer.fontSize};
  width: ${tokens.Table.columnResizer.width};
  height: ${tokens.Table.columnResizer.height};
  padding: 0 ${tokens.Table.columnResizer.padding};
  background: linear-gradient(${tokens.Table.columnResizer.color}, ${tokens.Table.columnResizer.color}) no-repeat
    center/2px 100%;
`;

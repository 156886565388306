import { Item, Root } from '@radix-ui/react-radio-group';
import styled, { css } from 'styled-components';
import { CheckCircleIcon, CheckPenIcon, MiniDotIcon } from '../Icons';
import { Stack } from '../Layout';
import { BaseColor, theme } from '../theme';
import { getBoxShadow } from '../theme/utils';
import { Text } from '../Typography/Text';
import { tokens } from './tokens';
import { Placement } from './types';
export const StyledRoot = styled(Root) `
  display: flex;
`;
export const StyledGroupIconSection = styled(Stack) `
  width: 100%;
  height: 60px;
  border-radius: ${tokens.RadioGroup.borderRadius} ${tokens.RadioGroup.borderRadius} 0px 0px;
`;
export const StyledCheckMark = styled(CheckCircleIcon) `
  &&&&& {
    ${({ checkboxPlacement, isButtonGroup }) => checkboxPlacement === Placement.left
    ? `margin-right: ${isButtonGroup ? tokens.RadioGroup.gap : tokens.RadioItem.marginRight};`
    : ''}

    font-size: ${({ size, isButtonGroup }) => isButtonGroup ? tokens.RadioItem[size].radioIconSize : tokens.RadioItem[size].size};
    ${({ disabled, checked }) => disabled
    ? 'opacity: 0.6;'
    : checked
        ? `
    color: ${tokens.RadioItem.selected.Icon.borderColor};
    border-radius: 50%;
    &:hover, &:focus, &:active{
     box-shadow: ${getBoxShadow(BaseColor.primary, 100)};
    }
  `
        : ''}
  }
`;
export const StyledCheckPen = styled(CheckPenIcon) `
  font-size: ${theme.fontSizes.x20};
  margin-right: ${theme.spaces.x8};
  color: ${theme.colors.success500};
`;
export const StyledMiniDotIcon = styled(MiniDotIcon) ``;
export const StyledRadio = styled.div `
  width: ${({ size }) => tokens.RadioItem[size].size};
  height: ${({ size }) => tokens.RadioItem[size].size};
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  &&&& ${StyledMiniDotIcon}, &&&& ${StyledCheckMark} {
    font-size: ${({ size }) => tokens.RadioItem[size].fontSize};
    color: ${({ disabled, checked }) => disabled
    ? tokens.RadioItem.disabled.color
    : checked
        ? tokens.RadioItem.selected.Icon.borderColor
        : tokens.RadioItem.iconColor};
  }

  ${({ disabled, checked }) => `
    border-radius: 50%;
    border: ${disabled ? tokens.RadioItem.selected.borderWidth : tokens.RadioItem.normal.borderWidth} solid ${disabled ? tokens.RadioItem.disabled.Icon.borderColor : tokens.RadioItem.normal.borderColor};
    background: ${disabled ? tokens.RadioItem.disabled.Icon.backgroundColor : 'transparent'};
    &:hover{
      background: ${disabled ? tokens.RadioItem.disabled.Icon.backgroundColor : tokens.RadioItem.selected.Icon.backgroundColor};
      border-color: ${disabled ? tokens.RadioItem.disabled.Icon.borderColor : tokens.RadioItem.selected.Icon.borderColor};
    }
    ${checked
    ? `
     border-width: ${tokens.RadioItem.selected.borderWidth};
     border-color: ${disabled ? tokens.RadioItem.disabled.Icon.borderColor : tokens.RadioItem.selected.borderColor};
      &:hover, &:focus, &:active{
        box-shadow: ${disabled ? 'none' : getBoxShadow(BaseColor.primary, 100)};
      }
    `
    : ''}
  `}
`;
export const commonCss = css `
  all: unset;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: flex-start;
  align-items: ${({ alignY }) => alignY || 'center'};
  &[data-state='checked'],
  &:hover {
    ${StyledGroupIconSection} {
      background: ${({ disabled }) => disabled ? tokens.RadioItem.disabled.cardIconBackground : tokens.RadioItem.selected.primary.cardIconBackground};
    }
  }
  &&& ${StyledMiniDotIcon}, &&& ${StyledCheckMark} {
    color: ${({ disabled, checked }) => disabled
    ? tokens.RadioItem.disabled.color
    : checked
        ? tokens.RadioItem.selected.Icon.borderColor
        : tokens.RadioItem.color};
  }
  ${({ isCard, disabled }) => isCard
    ? `
    cursor: pointer;
    width: 140px;
    border-radius: ${tokens.RadioGroup.borderRadius};
    &&& {
      height: 113px;
    }
   ${StyledGroupIconSection}{
      background: ${disabled ? tokens.RadioItem.disabled.cardIconBackground : tokens.RadioItem.selected.white.cardIconBackground};
    }
  `
    : ``}

  ${({ checked, disabled, tone }) => {
    var _a;
    return `
    background: ${disabled
        ? tokens.RadioItem.disabled.backgroundColor
        : checked && tone
            ? (_a = tokens.RadioItem.selected[tone]) === null || _a === void 0 ? void 0 : _a.backgroundColor
            : tokens.RadioItem.normal.backgroundColor};
    
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    opacity: ${disabled ? '0.7' : '1'};
    border: 1px solid ${tokens.RadioItem.normal.borderColor};
    &:hover, &:focus, &:active{
      border-color: ${disabled ? tokens.RadioItem.normal.borderColor : tokens.RadioItem.normal.hover.borderColor};
      &[data-state='checked'], &[data-state='checked'] ${StyledRadio}, &[data-state='checked'] ${StyledCheckMark}{
        box-shadow: ${disabled ? 'none' : getBoxShadow(BaseColor.primary, 100)};
      }
      & ${StyledRadio}{
        background: ${disabled ? tokens.RadioItem.disabled.Icon.backgroundColor : tokens.RadioItem.selected.Icon.backgroundColor};
        border-color: ${disabled ? tokens.RadioItem.disabled.Icon.borderColor : tokens.RadioItem.selected.Icon.borderColor};
      }
    }
     &[data-state='checked'], &[data-state='checked'] ${StyledRadio} {
        border-width: ${tokens.RadioItem.selected.borderWidth};
        &,&:hover{
          border-color: ${disabled ? tokens.RadioItem.normal.borderColor : tokens.RadioItem.selected.borderColor};
          ${StyledRadio}{
             border-color: ${disabled ? tokens.RadioItem.disabled.Icon.borderColor : tokens.RadioItem.selected.borderColor};
          }
        }
     }
  `;
}}
`;
export const GroupItemCss = css `
  ${StyledMiniDotIcon}, ${StyledCheckMark} {
    font-size: ${({ size }) => tokens.RadioItem[size].fontSize};
  }
  width: ${({ width }) => width};
  border-radius: ${tokens.RadioGroup.borderRadius};
  padding: ${tokens.RadioGroup.padding};
`;
export const StyledRadioItem = styled(Item) `
  ${commonCss}

  ${({ disabled }) => disabled
    ? ''
    : `
    &[data-state='checked'] ${StyledCheckMark}{
       border-radius: 50%;
       &:hover, &:active, &:focus{
        box-shadow: ${getBoxShadow(BaseColor.primary, 100)};
      }
    }
  `}

  ${({ headingWithBorder, disabled, disableBorder, checkboxPlacement }) => headingWithBorder
    ? `
    &&&, &&&:hover, &&&[data-state='checked']{
      padding: 0;
      flex-direction: column;
      display: flex;
      ${disabled ? '' : 'background: transparent;'}
    }
  `
    : disableBorder
        ? `&&&&, &&&&:hover, &&&&[data-state='checked']{
        border: none;
        ${checkboxPlacement === Placement.left ? `gap: ${tokens.RadioGroup.gap};` : ''}
        box-shadow: none;
        background: none;
      }`
        : ''}
`;
export const StyledRadioLabel = styled(Text) `
  flex: 1;
  font-size: ${({ size }) => tokens.RadioItem[size].fontSize};
  min-width: 0;
  font-weight: ${tokens.RadioItem.fontWeight};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: ${({ disabled }) => (disabled ? tokens.RadioItem.disabled.color : tokens.RadioItem.color)};
`;
export const StyledOptionsWrap = styled(Stack) `
  ${({ wrap }) => (wrap ? 'flex-wrap: wrap;' : '')}
  ${StyledRadioItem} {
    ${({ direction }) => (direction === 'vertical' ? `` : 'height: 100%;')}
  }
`;
export const StyledContentWrap = styled(Stack) `
  width: 100%;
  height: 100%;
  position: relative;

  ${({ headingWithBorder, disabled, checked, tone }) => headingWithBorder
    ? `
    padding: ${tokens.RadioGroup.padding};
    border-bottom: ${tokens.RadioItem.normal.borderWidth} solid ${tokens.RadioItem.normal.borderColor};
    background: ${disabled ? tokens.RadioItem.disabled.backgroundColor : 'transparent'};
    border-radius: ${tokens.RadioGroup.borderRadius} ${tokens.RadioGroup.borderRadius} 0 0;
    &:hover{
      background: ${disabled ? tokens.RadioItem.disabled.backgroundColor : tokens.RadioItem.selected.Icon.backgroundColor};
      border-color: ${disabled ? tokens.RadioItem.disabled.Icon.borderColor : tokens.RadioItem.selected.Icon.borderColor};
    }
    ${checked
        ? `
     border-width: ${disabled ? tokens.RadioItem.normal.borderWidth : tokens.RadioItem.selected.borderWidth};
      &,&:hover{
        background: ${disabled ? tokens.RadioItem.disabled.backgroundColor : tokens.RadioItem.selected[tone].backgroundColor};
        border-color: ${disabled ? tokens.RadioItem.disabled.Icon.borderColor : tokens.RadioItem.selected.borderColor};
      }
    `
        : ''}
  `
    : ''}
`;
